<template>
    <section class="panel-left-container">
        <div class="row mx-0 bg-fondo border br-8 mt-3 px-2 py-2">
            <div class="col-auto text-center">
                <img src="/img/gaming/i_pedido.svg" width="26" height="26" />
                <p class="text-general2 mt-1 f-14">
                    Pedidos
                </p>
            </div>
            <div class="col pr-2 pb-2 border-bottom d-flex">
                <div class="col pl-0">
                    <p class="text-general f-17 f-500">
                        {{ separadorNumero(_.get(estadistica, 'primerPedido.valor_final', '0.0')) }}
                    </p>
                    <small class="text-general">
                        <span class="">{{ formatearFecha(_.get(estadistica, 'primerPedido.entrega_fecha')) }}</span>
                    </small>
                    <small class="text-gris2 ml-2">
                        1er. pedido
                    </small>
                </div>
                <div class="col">
                    <p class="text-general f-17 f-500">
                        {{ separadorNumero(_.get(estadistica, 'ultimoPedido.valor_final', '0.0')) }}
                    </p>
                    <small class="text-general">
                        <span class="">{{ formatearFecha(_.get(estadistica, 'ultimoPedido.entrega_fecha')) }}</span>
                    </small>
                    <small class="text-gris2 ml-2">
                        Ult. pedido
                    </small>
                </div>
                <div class="col-auto px-0">
                    <p class="text-general f-17 f-500">
                        {{ separadorNumero(_.get(estadistica, 'comprasTotales', '0.0')) }}
                    </p>
                    <small class="text-general">
                        Compras totales
                    </small>
                </div>
            </div>
            <div class="col-12 pl-5 d-flex mt-2">
                <div class="col-auto" />
                <div class="col-auto text-general d-middle">
                    <i class="icon-leechero-cap f-18" />
                    <span class="f-15 ml-2">{{ _.get(estadistica, 'tienda.propietario_nombre', 'No registra') }}</span>
                    <el-tooltip v-if="_.get(estadistica, 'cantidadTienda', 0) > 1" class="item" effect="light" content="Cantidad de Vendedores asociados" placement="bottom">
                        <div class="mn-pill bg-general3 text-white ml-2">
                            {{ _.get(estadistica, 'cantidadTienda', 0) }}
                        </div>
                    </el-tooltip>
                </div>
                <div class="col-auto text-general d-middle">
                    <i class="icon-cedis f-18" />
                    <span class="f-15 ml-2">{{ _.get(estadistica, 'tienda.cedis_nombre', 'No registra') }}</span>
                </div>
            </div>
        </div>
        <div class="row mx-0 bg-fondo border br-8 my-3 px-2 py-2">
            <div class="col-auto text-center">
                <img src="/img/gaming/i_cupon.svg" width="26" height="26" />
                <p class="text-general2 mt-1 f-14">
                    Pedidos
                </p>
            </div>
            <div class="col pr-2 d-flex">
                <div class="col pl-0">
                    <p class="text-general f-17 f-500">
                        {{ agregarSeparadoresNumero(_.get(estadistica, 'cuponesCount', 0)) }}
                    </p>
                    <small class="text-general">
                        Aplicados
                    </small>
                </div>
                <div class="col">
                    <p class="text-general f-17 f-500">
                        {{ agregarSeparadoresNumero(_.get(estadistica, 'cuponesDisponibles', 0)) }}
                    </p>
                    <small class="text-general">
                        Disponibles
                    </small>
                </div>
                <div class="col-auto px-0">
                    <p class="text-general f-17 f-500">
                        {{ separadorNumero(_.get(estadistica, 'cuponesSum', 0)) }}
                    </p>
                    <small class="text-general">
                        Compras con cupón
                    </small>
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-4 align-items-center">
            <div class="col-auto px-0 text-general f-17 f-400">
                Interacciones por plataforma
            </div>
            <div class="col-auto px-0 text-general f-15 ml-auto">
                Último ingreso: {{ formatearFecha(_.get(estadistica, 'ultimoAcceso')) }}
            </div>
            <div class="col-12 px-0 mt-2 br-6 d-flex" style="height:30px;background-color:#F6F9FB;">
                <div class="h-100 br-6" :style="`background-color:#000000;width:${porcentajesBordes(percent(countAcceso(1)), 1)}%;`" />
                <div class="h-100" :style="`border-radius:0px 6px 6px 0px;background-color:#DFE4E8;width:${porcentajesBordes(percent(countAcceso(2)), 1)}%;`" />
            </div>
            <div class="col-12 px-0 mt-3 d-flex justify-center">
                <div class="col px-2 d-flex">
                    <div class="bg-general3 br-10" style="width:5px;height:55px;" />
                    <div class="border w-100 br-r-4">
                        <div class="row mx-0 align-items-center">
                            <i class="icon-web f-22 text-general f-18" />
                            <p class="col text-general f-15 f-600">
                                Web {{ Math.round(percent(countAcceso(1))) }}%
                            </p>
                        </div>
                        <div class="row mx-0">
                            <div class="col-auto" />
                            <div class="col text-general text-muted3 f-14">
                                {{ agregarSeparadoresNumero(countAcceso(1)) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col px-2 d-flex">
                    <div class="bg-general2 br-10" style="width:5px;height:55px;" />
                    <div class="border w-100 br-r-4">
                        <div class="row mx-0 align-items-center">
                            <i class="icon-android f-22 text-general f-18" />
                            <p class="col text-general f-15 f-600">
                                Android {{ Math.round(percent(countAcceso(2))) }}%
                            </p>
                        </div>
                        <div class="row mx-0">
                            <div class="col-auto" />
                            <div class="col text-general text-muted3 f-14">
                                {{ agregarSeparadoresNumero(countAcceso(2)) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col px-2 d-flex">
                    <div class="bg-light-f5 br-10" style="width:5px;height:55px;" />
                    <div class="border w-100 br-r-4">
                        <div class="row mx-0 align-items-center">
                            <i class="icon-apple f-22 text-general f-18" />
                            <p class="col text-general f-15 f-600">
                                IOS {{ Math.round(percent(countAcceso(3))) }}%
                            </p>
                        </div>
                        <div class="row mx-0">
                            <div class="col-auto" />
                            <div class="col text-general text-muted3 f-14">
                                {{ agregarSeparadoresNumero(countAcceso(3)) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p class="text-general f-17 f-400 mt-5">
            Compras y pedidos de las últimas 5 meses
        </p>
        <echart :options="comprasPedidos" />
    </section>
</template>

<script>

let colorGeneral = getComputedStyle(document.documentElement).getPropertyValue('--color-general')


export default {
    props: {
        estadistica: {
            type: Object,
            default: () => ({})
        }
    },

    computed: {
        maxAccesos(){
            if(_.isEmpty(this.estadistica.userAccesos)) return 0
            let {count: count1 = 0} = this.estadistica.userAccesos.find(el => el.tipo == 1) ?? {}
            let {count: count2 = 0} = this.estadistica.userAccesos.find(el => el.tipo == 2) ?? {}
            let {count: count3 = 0} = this.estadistica.userAccesos.find(el => el.tipo == 3) ?? {}
            return count1 + count2 + count3
        },
        comprasPedidos(){
            return {
                legend: {
                    top: 20,
                    itemGap: 15,
                    data: ['Compras', 'Pedidos'],
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    padding: 10,
                },
                dataset:{
                    dimensions:['semana', 'cantidad', 'valor_final'],
                    source: this.estadistica.semanas || []
                },
                xAxis: {
                    axisLabel: {
                        show: false,
                        margin: 20,
                        color: '#595959',
                        fontSize: 14,
                    },
                    type: 'category',
                    nameLocation: 'center',
                    nameGap: 50,
                    axisLine: {
                        lineStyle: {
                            type: 'dashed',
                            color: '#ECECEC',
                        },
                    },
                },
                yAxis: [
                    {
                        nameTextStyle: {
                            color: '#222',
                            fontSize: 14,
                        },
                        axisLabel: {
                            margin: 20,
                            color: '#595959',
                            fontSize: 14,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed',
                                color: '#ECECEC',
                            },
                        },
                        axisLine: {
                            show: false,
                        },
                        type: 'value',
                        minInterval: 1,
                        nameGap: 20,
                    },
                    {
                        nameTextStyle: {
                            color: '#222',
                            fontSize: 14,
                        },
                        axisLabel: {
                            margin: 20,
                            color: '#595959',
                            fontSize: 14,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed',
                                color: '#ECECEC',
                            },
                        },
                        axisLine: {
                            show: false,
                        },
                        type: 'value',
                        minInterval: 1,
                        nameGap: 20,
                    },
                ],
                series: [
                    {
                        type: 'line',
                        name: 'Pedidos',
                        yAxisIndex: 0,
                        symbol: 'emptyCircle',
                        smooth: 0.3,
                        symbolSize: 8,
                        itemStyle: {
                            borderWidth: 2,
                            color: '#612AD5',
                        },
                        emphasis: {
                            itemStyle: {
                                color: '#612AD5',
                                borderColor: '#fff',
                            },
                        },
                    },
                    {
                        type: 'bar',
                        name: 'Compras',
                        yAxisIndex: 1,
                        showBackground: true,
                        barWidth: '20%',
                        itemStyle: {
                            color: '#DBDBDB',
                        },
                    },
                ],
            }
        }
    },
    methods: {
        countAcceso(tipo = 1){
            if(_.isEmpty(this.estadistica.userAccesos)) return 0
            let {count = 0} = this.estadistica.userAccesos.find(el => el.tipo == tipo) ?? {}
            return count

        },
        percent(count){
            return (count * 100) / this.maxAccesos
        }
    }
}
</script>

<style lang="scss" scoped>
.card-percentage{
    width: 167px;
    height: 48px;
    border-radius: 4px;
    border: 1px solid #DFE4E8;
}
</style>
